<template>
  <div>
    <div class="mb-4 row">
      <div class="pl-5 col">
        <div class="pl-4 profile">
          <b-alert show variant="success">
            С политикой конфиденциальности Вы можете ознакомиться на странице
            <a href="https://rhsolutions.ru/confidentiality/" class="link">rhsolutions.ru/confidentiality</a>
          </b-alert>
          <div class="mb-3">
            <h1 class="h4">{{ user.firstName }} {{ user.lastName }}</h1>
          </div>
          <div class="mb-2"><b>Роль:</b> {{ roleTranslate(user.role) }}</div>
          <div class="mb-2" v-if="isLeader || isManager"><b>Регион:</b> {{ user.region }}</div>
          <div class="mb-2"><b>Логин:</b> {{ user.name }}</div>
        </div>
      </div>
    </div>

    <div class="row" v-if="isManufacturer">
      <div class="pl-5 ml-4 col">
        <div class="mb-3">
          <h2 class="h4">Производитель {{ manufacturer.inn }} {{ manufacturer.name }}</h2>
        </div>
        <div class="mb-2"><b>Менеджер РЕХАУ:</b> {{ manufacturer.manager }}</div>
        <div class="mb-2"><b>Контактное лицо:</b> {{ manufacturer.contactPersonFirstName }} {{ manufacturer.contactPersonLastName }}</div>
        <div class="mb-2"><b>Фактический адрес:</b> {{ manufacturer.address }}</div>
        <div class="mb-2"><b>Телефон:</b> {{ manufacturer.contactPersonPhone }}</div>
        <div class="mb-2"><b>Сайт:</b> {{ manufacturer.webSite }}</div>
        <div class="mb-4"><b>Email:</b> {{ manufacturer.contactPersonEmail }}</div>
        <b-button variant="success" @click="onProfilerEdit('ManufacturerForm', manufacturer)">Редактировать</b-button>
      </div>
    </div>

    <div class="row" v-if="isDealer">
      <div class="pl-5 ml-4 col">
        <div class="mb-3">
          <h2 class="h4">Партнер {{ partner.inn }} {{ partner.name }}</h2>
        </div>
        <div class="mb-2"><b>Менеджер РЕХАУ:</b> {{ partner.manager }}</div>
        <div class="mb-2"><b>Контактное лицо:</b> {{ partner.contactPersonFirstName }} {{ partner.contactPersonLastName }}</div>
        <div class="mb-2"><b>Телефон:</b> {{ partner.contactPersonPhone }}</div>
        <div class="mb-2"><b>Сайт:</b> {{ partner.webSite }}</div>
        <div class="mb-4"><b>Email:</b> {{ partner.contactPersonEmail }}</div>
        <b-button variant="success" @click="onProfilerEdit('PartnerForm', partner)">Редактировать</b-button>
      </div>
    </div>    

    <RightSidebar
      :form="profileFormName"
      :form-data="profileFormData"
      :mode="profileFormMode"
      :show="profileFormShow"
      @update:show="profileFormShow = $event;profileFormData = {}"
      @saved:form-data="onSavedProfileForm"
    ></RightSidebar>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from "vuex";
import { Role } from "@/constants";
import { RightSidebar } from "@/components";

export default {
  name: "Profile",
  components: {
    RightSidebar
  },
  data() {
    return {
      userUpdate: {},
      profileFormName: null,
      profileFormData: {},
      profileFormMode: null,
      profileFormShow: false
    };
  },
  methods: {
    ...mapActions("manufacturers", ["fetchManufacturerById", "changeManufacturerData"]),
    ...mapActions("partners", ["fetchPartner", "changePartnerData"]),
    ...mapActions(["updateUser"]),

    onProfilerEdit(formName, formData){
      this.profileFormMode = "EDIT:PROFILE"
      this.profileFormName = formName
      this.profileFormData = {... formData, ...{
        id: (this.isManufacturer) ? this.manufacturer.id : (this.isDealer) ? this.partner.id : null
      }}
      this.profileFormShow = true
    },

    onSavedProfileForm() {
      const method = this.isManufacturer ? this.fetchManufacturerById : this.fetchPartner
      method(this.partnerId);
      this.userUpdate.firstName = this.profileFormData.contactPersonFirstName;
      this.userUpdate.lastName = this.profileFormData.contactPersonLastName;
      this.updateUser(this.userUpdate);
    },

    roleTranslate(role) {
      return Role.translate(role)
    },
    
    getProfileData() {
      if (this.isManufacturer && this.partnerId) {
        this.fetchManufacturerById(this.partnerId);
      }
      if (this.isDealer && this.partnerId) {
        this.fetchPartner(this.partnerId);
      }
      this.userUpdate.id = this.user.id;
      this.userUpdate.role = this.user.role;
      this.userUpdate.name = this.user.name;
      this.userUpdate.email = this.user.email;
      this.userUpdate.firstName = this.user.firstName;
      this.userUpdate.lastName = this.user.lastName;
      this.userUpdate.partnerId = this.user.partnerId;
      this.userUpdate.region = this.user.region;
      this.userUpdate.isAgree = this.user.isAgree;
    }
  },
  computed: {
    ...mapGetters(["isManager", "isLeader", "isManufacturer", "isDealer"]),
    ...mapState({
      user: state => state.auth.user,
      partnerId: state => state.auth.user.partnerId
    }),
    ...mapState("manufacturers", {
      manufacturer: state => state.manufacturer
    }),
    ...mapState("partners", {
      partner: state => state.partner
    })    
  },
  created() {
    this.getProfileData();
  }
};
</script>

<style lang="scss" scoped></style>
